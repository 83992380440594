<template>

  <BlockUI :blocked="loading>0">

    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-user-edit" style="font-size: 1.6rem"></i> Cadastro de Pessoa</h3>
      </div>

      <div class="p-col-12 p-md-6">
        <Button
          id="buttonGravar"
          :label="(tabAtiva == 0)?'Continuar':'Gravar'"
          icon="pi pi-check"
          style="float: right"
          class="p-mr-2 p-mb-3 p-button-lg p-button-success"
          @click="criar_alterar"
          v-if="$auth.userCan('PESSOAS_CADASTRAR')"
        ></Button>
      </div>
    </div>

    <TabView v-model:activeIndex="tabAtiva">
      <TabPanel header="1: Informações Gerais">
        <div class="p-grid p-pt-3">
          <div class="p-col-12 p-md-6">
            <!-- Informações Básicas -->
            <h5><i class="pi pi-id-card"></i> Identificação</h5>
            <div class="card p-fluid">
              <div class="p-grid">
                <div class="p-field p-md-3">
                  <label for="cpf">CPF</label>
                  <InputMask
                    id="cpf"
                    mask="999.999.999-99"
                    v-model="cpf"
                    @blur="validaCPF"
                    @focus="cpfInvalido = false; cpfPrevioValidacao = cpf"
                    :class="(fieldsError.some(v => v === 'cpf'))?'p-invalid':''"
                  />
                  <InlineMessage v-if="cpfInvalido">CPF Inválido</InlineMessage>
                </div>

                <div class="p-field p-md-9">
                  <label for="nome">Nome Completo</label>
                  <InputText
                    id="nome"
                    type="text"
                    v-model="nome"
                    maxlength="200"
                    :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
                  />
                </div>

                <div class="p-field p-md-3">
                  <label for="nascimento">Nascimento</label>
                  <InputMask
                    v-model="nascimento"
                    :class="(fieldsError.some(v => v === 'nascimento'))?'p-invalid':''"
                    mask="99/99/9999"
                    slotChar="dd/mm/aaaa"
                  />
                </div>

                <div class="p-field p-col-9">
                  <label for="email">E-Mail</label>
                  <InputText
                    id="email"
                    type="text"
                    v-model="email"
                    :class="(fieldsError.some(v => v === 'email'))?'p-invalid':''"
                    maxlength="200"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-6">
            <!-- Telefones -->
            <h5 :style="(fieldsError.some(v => v === 'telefones'))?'color: red':''"><i class="pi pi-phone"></i> Telefones</h5>
            <div class="card p-fluid">
              <div class="p-grid">

                <div class="p-field p-md-6">
                  <label for="telefone">Número de Telefone</label>
                  <InputMask
                    id="telefone"
                    type="text"
                    v-model="telefone"
                    :class="(fieldsError.some(v => v === 'telefone'))?'p-invalid':''"
                    mask="(99) 9999-9999?9"
                    @focus="telefoneInvalido = false"
                  />
                  <InlineMessage v-if="telefoneInvalido">
                    O Telefone é inválido
                  </InlineMessage>
                </div>

                <div class="p-field p-md-6">
                  <label for="telefone_nome">Observações (opcional)</label>
                  <InputText
                    id="telefone_nome"
                    type="text"
                    v-model="telefone_nome"
                    :class="(fieldsError.some(v => v === 'telefone_nome'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-6">
                  <div class="p-field-checkbox">
                    <Checkbox
                      id="e_whatsapp"
                      v-model="telefone_e_whatsapp"
                      :binary="true"
                    />
                    <label for="e_whatsapp">O telefone é WhatsApp.</label>
                  </div>
                </div>

                <div class="p-field p-md-6">
                  <Button
                    label="Adicionar Telefone"
                    @click="adicionarTelefone"
                  ></Button>
                </div>

                <div class="p-field" v-if="telefones.length">
                  <DataTable sortMode="single" :value="telefones">
                    <Column field="nome" headerClass="colNome" header="Observações">
                      <template #body="slotProps">
                        {{
                          slotProps.data.nome
                            ? slotProps.data.nome
                            : "Nenhuma"
                        }}
                      </template>
                    </Column>
                    <Column
                      field="telefone"
                      headerClass="colTelefone"
                      headerStyle="width:145px"
                      header="Telefone"
                    >
                      <template #body="slotProps">
                        {{ $utils.formatPhone(slotProps.data.telefone) }}
                      </template>
                    </Column>
                    <Column
                      field="tipo"
                      headerClass="colTipo"
                      headerStyle="width:67px"
                      header="Tipo"
                    ></Column>
                    <Column
                      field="e_whatsapp"
                      headerClass="colWhatsapp"
                      headerStyle="width:65px"
                      header="Whatsapp?"
                    >
                      <template #body="slotProps">
                        {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                      </template>
                    </Column>
                    <Column headerStyle="width:52px">
                      <template #body="slotProps">
                        <Button
                          icon="pi pi-trash"
                          class="p-mr-2 p-mb-2 p-button-danger"
                          @click="removerTelefone($event, slotProps.index)"
                        ></Button>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>

          </div>

          <div class="p-col-12 p-md-6" style="display: none">
            <!-- Endereço -->
            <h5><i class="pi pi-map-marker"></i> Endereço Atual</h5>
            <div class="card p-fluid">
              <div class="p-grid">
                <div class="p-field p-md-3">
                  <label for="residencia_cep">CEP</label>
                  <InputMask
                    id="residencia_cep"
                    type="text"
                    v-model="residencia_cep"
                    :class="(fieldsError.some(v => v === 'residencia_cep'))?'p-invalid':''"
                    mask="99.999-999"
                    :unmask="true"
                    @change="validaCEP"
                    @focus="cepInvalido = false; cepPrevioValidacao = residencia_cep"
                  />
                  <InlineMessage v-if="cepInvalido">CEP Inválido</InlineMessage>
                </div>

                <div class="p-field p-md-9">
                  <label for="residencia_logradouro">Endereço</label>
                  <InputText
                    id="residencia_logradouro"
                    type="text"
                    v-model="residencia_logradouro"
                    :class="(fieldsError.some(v => v === 'residencia_logradouro'))?'p-invalid':''"
                    maxlength="300"
                  />
                </div>

                <div class="p-field p-md-2">
                  <label for="residencia_numero">Número</label>
                  <InputText
                    id="residencia_numero"
                    type="text"
                    v-model="residencia_numero" :class="(fieldsError.some(v => v === 'residencia_numero'))?'p-invalid':''"
                    maxlength="10"
                  />
                </div>

                <div class="p-field p-md-5">
                  <label for="residencia_complemento">Complemento</label>
                  <InputText
                    id="residencia_complemento"
                    type="text"
                    v-model="residencia_complemento" :class="(fieldsError.some(v => v === 'residencia_complemento'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-5">
                  <label for="residencia_bairro">Bairro</label>
                  <InputText
                    id="residencia_bairro"
                    type="text"
                    v-model="residencia_bairro" :class="(fieldsError.some(v => v === 'residencia_bairro'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-5">
                  <label for="residencia_cidade">Cidade</label>
                  <InputText
                    id="residencia_cidade"
                    type="text"
                    v-model="residencia_cidade" :class="(fieldsError.some(v => v === 'residencia_cidade'))?'p-invalid':''"
                    maxlength="100"
                  />
                </div>

                <div class="p-field p-md-2">
                  <label for="residencia_uf">UF</label>
                  <InputText
                    id="residencia_uf"
                    type="text"
                    v-model="residencia_uf" :class="(fieldsError.some(v => v === 'residencia_uf'))?'p-invalid':''"
                    maxlength="20"
                  />
                </div>

                <div class="p-field p-md-5">
                  <label for="residencia_situacao">Situação</label>
                  <Dropdown
                    id="residencia_situacao"
                    v-model="residencia_situacao" :class="(fieldsError.some(v => v === 'residencia_situacao'))?'p-invalid':''"
                    :options="residencias_situacoes"
                    optionLabel="nome"
                    optionValue="id"
                    placeholder="Selecione"
                  />
                </div>

              </div>
            </div>

          </div>
        </div>
      </TabPanel>

      <TabPanel header="2: Outras Informações">
        <div class="p-grid">
          
          <div class="p-col-12">

            <!-- Rendas -->
            <h5><i class="pi pi-dollar"></i> Rendas</h5>
            <div class="card p-fluid">

              <div class="p-grid">

                <div class="p-field p-md-3">
                  <label for="possui_profissao">A pessoa possui Renda?</label>
                  <SelectButton
                    id="possui_profissao"
                    v-model="possui_profissao" :class="(fieldsError.some(v => v === 'possui_profissao'))?'p-invalid':''"
                    :options="sim_nao"
                    optionLabel="nome"
                    optionValue="id"
                  />
                </div>

                <div class="p-field p-md-2" v-if="possui_profissao">
                  <label for="renda_fixa">Renda mensal Total</label>
                  <InputNumber
                    id="renda_fixa"
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    v-model="renda_fixa"
                    :class="
                      fieldsError.some((v) => v === 'renda_fixa') ? 'p-invalid' : ''
                    "
                    @focus="$utils.inputNumberFocus"
                  /> 
                </div>

                <div class="p-field p-md-3" v-if="possui_profissao">
                  <label for="vinculo_empregaticio">Principal fonte de Renda</label>
                  <Dropdown
                    id="vinculo_empregaticio"
                    v-model="vinculo_empregaticio" :class="(fieldsError.some(v => v === 'vinculo_empregaticio'))?'p-invalid':''"
                    :options="vinculos_empregaticios"
                    optionLabel="nome"
                    optionValue="id"
                    placeholder="Selecione"
                  />
                </div>

                <!-- <div class="p-field p-md-4">
                  <label for="possui_outra_renda"
                    >Outras Rendas ou é
                    Aposentado/Pensionista?</label
                  >
                  <SelectButton
                    id="possui_outra_renda"
                    v-model="possui_outra_renda" :class="(fieldsError.some(v => v === 'possui_outra_renda'))?'p-invalid':''"
                    :options="sim_nao"
                    optionLabel="nome"
                    optionValue="id"
                  />
                </div> -->

                <!-- <div class="p-field p-md-4" v-if="possui_outra_renda">
                  <label for="renda_outras_origem">Origem da Renda</label>
                  <InputText
                    id="renda_outras_origem"
                    type="text"
                    v-model="renda_outras_origem" :class="(fieldsError.some(v => v === 'renda_outras_origem'))?'p-invalid':''"
                  />
                </div> -->

                <!-- <div class="p-field p-md-2" v-if="possui_outra_renda">
                  <label for="renda_outras">Valor da Renda</label>
                  <InputNumber
                    id="renda_outras"
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    v-model="renda_outras"
                    :class="
                      fieldsError.some((v) => v === 'renda_outras') ? 'p-invalid' : ''
                    "
                    @focus="$utils.inputNumberFocus"
                  />
                </div> -->

              </div>
            </div>

          </div>

          <div class="p-col-12">

            <h5><i class="pi pi-users"></i> Cônjuge / Companheiro(a)</h5>

            <div class="card p-fluid">
              <div class="p-grid">
                <div class="p-field p-md-2">
                  <label for="estado_civil">Estado Civil</label>
                  <Dropdown
                    id="estado_civil"
                    v-model="estado_civil" :class="(fieldsError.some(v => v === 'estado_civil'))?'p-invalid':''"
                    :options="estados_civis"
                    optionLabel="nome"
                    optionValue="id"
                    placeholder="Selecione"
                  />
                </div>
                <div class="p-field p-md-10"></div>

                <!-- Conjuge -->
                <div class="p-col-12" v-if="possui_conjuge">
                  <div class="p-grid">

                    <div class="p-field p-md-2">
                      <label for="conjuge_cpf">CPF</label>
                      <InputMask
                        id="conjuge_cpf"
                        mask="999.999.999-99"
                        v-model="conjuge_cpf" :class="(fieldsError.some(v => v === 'conjuge_cpf'))?'p-invalid':''"
                        @blur="validaCPFConjuge"
                        @focus="cpfConjugeInvalido = false; cpfConjugePrevioValidacao = conjuge_cpf"
                      />
                      <InlineMessage v-if="cpfConjugeInvalido">CPF Inválido</InlineMessage>
                    </div>

                    <div class="p-field p-md-5">
                      <label for="conjuge_nome">Nome Completo</label>
                      <InputText
                        id="conjuge_nome"
                        type="text"
                        v-model="conjuge_nome" :class="(fieldsError.some(v => v === 'conjuge_nome'))?'p-invalid':''"
                        maxlength="200"
                      />
                    </div>

                    <div class="p-field p-md-2">
                      <label for="conjuge_nascimento">Nascimento</label>
                      <InputMask
                        v-model="conjuge_nascimento" 
                        :class="(fieldsError.some(v => v === 'conjuge_nascimento'))?'p-invalid':''"
                        mask="99/99/9999"
                        slotChar="dd/mm/aaaa"
                      />
                    </div>

                    <div class="p-field p-md-3">
                      <label for="conjuge_compoe_renda">O Conjuge possui Renda?</label>
                      <SelectButton
                        id="conjuge_compoe_renda"
                        v-model="conjuge_compoe_renda" :class="(fieldsError.some(v => v === 'conjuge_compoe_renda'))?'p-invalid':''"
                        :options="sim_nao"
                        optionLabel="nome"
                        optionValue="id"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Rendas -->
          <div class="p-col-12" v-if="conjuge_compoe_renda">
            <h5><i class="pi pi-dollar"></i> Rendas do Cônjuge</h5>
            <div class="card p-fluid">
              <div class="p-grid">

                <!-- <div class="p-field p-md-2">
                  <label for="conjuge_possui_profissao">O Conjuge possui Renda?</label>
                  <SelectButton
                    id="conjuge_possui_profissao"
                    v-model="conjuge_possui_profissao" :class="(fieldsError.some(v => v === 'conjuge_possui_profissao'))?'p-invalid':''"
                    :options="sim_nao"
                    optionLabel="nome"
                    optionValue="id"
                  />
                </div> -->

                <div class="p-field p-md-2" v-if="conjuge_possui_profissao">
                  <label for="conjuge_renda_fixa">Renda mensal Total</label>
                  <InputNumber
                    id="conjuge_renda_fixa"
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    v-model="conjuge_renda_fixa"
                    :class="
                      fieldsError.some((v) => v === 'conjuge_renda_fixa') ? 'p-invalid' : ''
                    "
                    @focus="$utils.inputNumberFocus"
                  />
                </div>

                <div class="p-field p-md-3" v-if="conjuge_possui_profissao">
                  <label for="conjuge_vinculo_empregaticio">Principal fonte de Renda</label>
                  <Dropdown
                    id="conjuge_vinculo_empregaticio"
                    v-model="conjuge_vinculo_empregaticio" :class="(fieldsError.some(v => v === 'conjuge_vinculo_empregaticio'))?'p-invalid':''"
                    :options="vinculos_empregaticios"
                    optionLabel="nome"
                    optionValue="id"
                    placeholder="Selecione"
                  />
                </div>

                <!-- <div class="p-field p-md-4">
                  <label for="conjuge_possui_outra_renda"
                    >Outras Rendas ou é
                    Aposentado/Pensionista?</label
                  >
                  <SelectButton
                    id="conjuge_possui_outra_renda"
                    v-model="conjuge_possui_outra_renda" :class="(fieldsError.some(v => v === 'conjuge_possui_outra_renda'))?'p-invalid':''"
                    :options="sim_nao"
                    optionLabel="nome"
                    optionValue="id"
                  />
                </div>

                <div class="p-field p-md-4" v-if="conjuge_possui_outra_renda">
                  <label for="conjuge_renda_outras_origem">Origem da Renda</label>
                  <InputText
                    id="conjuge_renda_outras_origem"
                    type="text"
                    v-model="conjuge_renda_outras_origem" :class="(fieldsError.some(v => v === 'conjuge_renda_outras_origem'))?'p-invalid':''"
                  />
                </div>

                <div class="p-field p-md-2" v-if="conjuge_possui_outra_renda">
                  <label for="conjuge_renda_outras">Valor da Renda</label>
                  <InputNumber
                    id="conjuge_renda_outras"
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    v-model="conjuge_renda_outras"
                    :class="
                      fieldsError.some((v) => v === 'conjuge_renda_outras') ? 'p-invalid' : ''
                    "
                    @focus="$utils.inputNumberFocus"
                  />
                </div> -->
              </div>
            </div>
          </div>

        </div>

      </TabPanel>

    </TabView>

    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>

  </BlockUI>

  <Dialog header="Imobiliária" :closable="false" :modal="true" v-model:visible="dialogImobiliaria">
    <div class="p-field">
      <label for="imobiliaria" class="p-d-block">Selecione a Imobiliária responsável por esse cadastro:</label>
      <Dropdown
        id="imobiliaria"
        v-model="imobiliaria_selecionada"
        :options="imobiliarias"
        :showClear="true"
        optionLabel="nome"
        optionValue="id"
        placeholder="Selecione"
        :filter="true"
        filterPlaceholder="Buscar Imobiliária"
        style="width: 300px"
      />
    </div>
  </Dialog>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>
import axios from "axios";

export default {
  props: {
    pessoa: Object,
    imobiliaria: Number
  },
  emits: [
    "save",
    "created",
    "updated"
  ],
  data() {
    return {
      // 1. Controle de apresentação
      loading: 5,
      tabAtiva: 0,

      dialogImobiliaria: false,

      imobiliarias: [],
      imobiliaria_selecionada: null,

      possui_profissao: false,
      autonomo: false,
      funcionario: false,
      possui_outra_renda: false,
      possui_conjuge: false,

      conjuge_possui_profissao: false,
      conjuge_autonomo: false,
      conjuge_funcionario: false,
      conjuge_possui_outra_renda: false,

      // 1.1. Validadores
      telefoneInvalido: false,
      cepInvalido: false,
      cpfInvalido: false,
      cpfConjugeInvalido: false,
      fieldsError: [],
      cepPrevioValidacao: "",
      cpfPrevioValidacao: "",
      cpfConjugePrevioValidacao: "",

      // 2. Chaves Estrangeiras
      sim_nao: [
        { nome: "Sim", id: 1 },
        { nome: "Não", id: 0 },
      ],
      ramos_atividades: [],
      estados_civis: [],
      residencias_situacoes: [],
      vinculos_empregaticios: [],

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      cpf: "",
      nome: "",
      rg: "",
      rg_orgao: "",
      rg_expedicao: "",
      nascimento: "",
      email: "",

      telefone_nome: "",
      telefone_e_whatsapp: false,
      telefone: "",

      telefones: [],

      residencia_cep: "",
      residencia_logradouro: "",
      residencia_numero: "",
      residencia_complemento: "",
      residencia_bairro: "",
      residencia_cidade: "",
      residencia_uf: "",
      residencia_situacao: "",
      residencia_anos: null,

      renda_fixa: 0.0,
      vinculo_empregaticio: null,
      profissao: "",
      profissao_empresa: "",
      profissao_empresa_admissao: "",

      renda_outras: 0.0,
      renda_outras_origem: "",

      estado_civil: "",

      conjuge_cpf: "",
      conjuge_nome: "",
      conjuge_rg: "",
      conjuge_rg_expedicao: "",
      conjuge_rg_orgao: "",
      conjuge_nascimento: "",
      conjuge_compoe_renda: 0,

      conjuge_renda_fixa: 0.0,
      conjuge_vinculo_empregaticio: null,
      conjuge_profissao: "",
      conjuge_profissao_empresa: "",
      conjuge_profissao_empresa_admissao: "",

      conjuge_renda_outras: 0.0,
      conjuge_renda_outras_origem: "",

      // 4. Dado selecionado para edição

      pessoa_edit: null,
      
    };
  },

  mounted() {
    const self = this;

    if(this.imobiliaria != "undefined" && this.imobiliaria != null) {
      this.imobiliaria_selecionada = this.imobiliaria;
    }

    this.loading++;

    this.$api
      .get("/imobiliarias/?fields=nome")
      .then(function (response) {
        const dados = response.data;
        if (dados.success) { 
          self.imobiliarias = dados.data;
        }

        self.loading--;

      });

    if(this.$auth.currentUser.imobiliaria == null) {

      if(typeof this.pessoa != "object" || this.pessoa == null) {

        if(typeof this.$route.params.id != "string" || this.$route.params.id == "") {
          this.dialogImobiliaria = true;
        }

      } else {

        this.imobiliaria_selecionada = this.pessoa.imobiliaria;        

      }

    }


    this.$api.get("/pessoas-ramos-atividades").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.ramos_atividades = dados.data;
      }

      self.loading--;

    });

    this.$api.get("/pessoas-estados-civis").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.estados_civis = dados.data;
      }

      self.loading--;

    });

    this.$api.get("/pessoas-residencias-situacoes").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.residencias_situacoes = dados.data;
      }

      self.loading--;

    });

    this.$api.get("/pessoas-vinculos-empregaticios").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.vinculos_empregaticios = dados.data;
        self.vinculos_empregaticios.push({ nome: "Selecione", id: -1 });
      }

      self.loading--;

    });

    this.carregarObjetoRota();
  },

  watch: {
    $route() {
      if(this.$route.name == "pessoa") {
        this.loading = 1;
        this.carregarObjetoRota();
      }
    },

    imobiliaria_selecionada(val) {
      if(typeof val == 'number') {
        this.dialogImobiliaria = false;
      }
    },

    possui_profissao: function (val) {
      if(!val) {
        this.vinculo_empregaticio = null;
      }
    },

    conjuge_compoe_renda: function(val) {
      this.conjuge_possui_profissao = val;
      if(!val) {
        this.conjuge_renda_fixa = 0.0;
        this.conjuge_renda_outras = 0.0;
      }
    },

    conjuge_possui_profissao: function (val) {
      if(!val) {
        this.conjuge_vinculo_empregaticio = null;
      }
    },

    estado_civil: function (val) {
      if(val) {
        const ec = this.estados_civis.filter((obj) => obj.id == val)[0];
        this.conjuge_compoe_renda = this.possui_conjuge = ec.possui_conjuge == 1;
      } else {
        this.conjuge_compoe_renda = this.possui_conjuge = false;
      }
    },

    pessoa_edit: function (val) {
      
      this.limparFormulario();

      if (val != null) {

        if(val.imobiliaria) {
          this.imobiliaria_selecionada = val.imobiliaria;
        }

        // 3. Dados de formulários

        // 3.1. Informações Gerais

        this.cpf = val.cpfcnpj;
        this.nome = val.nome;
        this.rg = val.rg;
        this.rg_orgao = val.rg_orgao;
        this.rg_expedicao = this.$utils.formatDateToBr(val.rg_expedicao);
        this.nascimento = this.$utils.formatDateToBr(val.nascimento);
        this.email = val.email;

        if(Array.isArray(val.contatos)) {
          for(const c of val.contatos) {
            this.telefones.push({
              nome: c.nome,
              telefone: c.telefone,
              tipo: c.tipo,
              e_whatsapp: c.e_whatsapp,
            });
          }
        }

        this.residencia_cep = val.cep;
        this.residencia_logradouro = val.logradouro;
        this.residencia_numero = val.numero;
        this.residencia_complemento = val.complemento;
        this.residencia_bairro = val.bairro;
        this.residencia_cidade = val.cidade;
        this.residencia_uf = val.uf;
        this.residencia_situacao = val.residencia_situacao;
        this.residencia_anos = val.residencia_anos;

        this.estado_civil = val.estado_civil;

        this.possui_profissao = val.renda_fixa > 0 ? 1 : 0;

        this.renda_fixa = val.renda_fixa;
        this.vinculo_empregaticio = val.vinculo_empregaticio;

        this.profissao = val.profissao;
        this.profissao_empresa = val.profissao_empresa;
        this.profissao_empresa_admissao = this.$utils.formatDateToBr(val.profissao_empresa_admissao);

        this.possui_outra_renda = val.renda_outras > 0 ? 1 : 0;
        this.renda_outras = val.renda_outras;
        this.renda_outras_origem = val.renda_outras_origem;

        if(typeof val.conjuge_item == "object" && val.conjuge_item != null) {
          
          this.possui_conjuge = true;

          this.conjuge_cpf = val.conjuge_item.cpfcnpj;
          this.conjuge_nome = val.conjuge_item.nome;
          this.conjuge_rg = val.conjuge_item.rg;
          this.conjuge_rg_expedicao = this.$utils.formatDateToBr(val.conjuge_item.rg_expedicao);
          this.conjuge_rg_orgao = val.conjuge_item.rg_orgao;
          this.conjuge_nascimento = this.$utils.formatDateToBr(val.conjuge_item.nascimento);

          this.conjuge_compoe_renda = (val.conjuge_item.renda_fixa > 0 || val.conjuge_item.renda_outras > 0)?1:0;
          this.conjuge_renda_fixa = val.conjuge_item.renda_fixa;
          this.conjuge_possui_profissao = val.conjuge_item.renda_fixa > 0;
          this.conjuge_vinculo_empregaticio = val.conjuge_item.vinculo_empregaticio;
          this.conjuge_profissao = val.conjuge_item.profissao;
          this.conjuge_profissao_empresa = val.conjuge_item.profissao_empresa;
          this.conjuge_profissao_empresa_admissao = this.$utils.formatDateToBr(val.conjuge_item.profissao_empresa_admissao);

          this.conjuge_possui_outra_renda = val.conjuge_item.renda_outras > 0 ? 1 : 0;

          this.conjuge_renda_outras = val.conjuge_item.renda_outras;
          this.conjuge_renda_outras_origem = val.conjuge_item.renda_outras_origem;

        }

      }
    },

    vinculo_empregaticio: function (val) {
      if(val && val != -1) {
        const ve = this.vinculos_empregaticios.filter((obj) => obj.id == val)[0];
        this.funcionario = ve.possui_empresa;
        this.autonomo = ve.possui_profissao;
      } else {
        this.autonomo = false;
        this.funcionario = false;
      }
    },

    conjuge_vinculo_empregaticio: function (val) {
      if(val && val != -1) {
        const ve = this.vinculos_empregaticios.filter((obj) => obj.id == val)[0];
        this.conjuge_funcionario = ve.possui_empresa;
        this.conjuge_autonomo = ve.possui_profissao;
      } else {
        this.conjuge_autonomo = false;
        this.conjuge_funcionario = false;
      }
    }
  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota(id = 0) {
      const self = this;

      let pessoa = null;
      if(id != 0) {
        pessoa = {
          "id": id
        };
      } else if(typeof this.pessoa != "undefined") {
        pessoa = this.pessoa;
      }

      if (
        (typeof this.$route.params.id != "string" ||
        this.$route.params.id == "") && pessoa == null
      ) {
        self.loading--;
        return;
      }

      let bloqueio = setInterval(() => {

        if(self.loading == 1) {

          if(pessoa != null && Object.keys(pessoa).length > 5) {
            self.pessoa_edit = self.$utils.getStdObject(pessoa);
            self.loading--;
            clearInterval(bloqueio);
          } else {

            let pessoaId;
            if(pessoa != null)
              pessoaId = pessoa.id;
            else
              pessoaId = this.$route.params.id;

            self.$api
              .get("/pessoas/" + pessoaId)
              .then(function (response) {
                const dados = response.data;

                if (dados.success) { 
                  self.pessoa_edit = dados.data;
                  self.loading = false;
                } else {
                  alert(dados.message);
                  self.$router.push({ path: `/pessoas` });
                }

                self.loading--;

                clearInterval(bloqueio);

              });
          }

        }

      },500);

    },

    limparFormulario() {
      //Limpa as variáveis

      this.possui_profissao = false;
      this.autonomo = false;
      this.funcionario = false;
      this.possui_outra_renda = false;
      this.possui_conjuge = false;

      this.conjuge_possui_profissao = false;
      this.conjuge_autonomo = false;
      this.conjuge_funcionario = false;
      this.conjuge_possui_outra_renda = false;

      // 1.1. Validadores
      this.telefoneInvalido = false;
      this.cepInvalido = false;
      this.cpfInvalido = false;
      this.cpfConjugeInvalido = false;

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      this.cpf = "";
      this.nome = "";
      this.rg = "";
      this.rg_orgao = "";
      this.rg_expedicao = "";
      this.nascimento = "";
      this.email = "";

      this.telefone_nome = "";
      this.telefone_e_whatsapp = false;
      this.telefone = "";

      this.telefones = [];

      this.residencia_cep = "";
      this.residencia_logradouro = "";
      this.residencia_numero = "";
      this.residencia_complemento = "";
      this.residencia_bairro = "";
      this.residencia_cidade = "";
      this.residencia_uf = "";
      this.residencia_situacao = "";
      this.residencia_anos = null;

      this.renda_fixa = 0.0;
      this.vinculo_empregaticio = null;
      this.profissao = "";
      this.profissao_empresa = "";
      this.profissao_empresa_admissao = "";

      this.renda_outras = 0.0;
      this.renda_outras_origem = "";

      this.estado_civil = "";

      this.conjuge_cpf = "";
      this.conjuge_nome = "";
      this.conjuge_rg = "";
      this.conjuge_rg_expedicao = "";
      this.conjuge_rg_orgao = "";
      this.conjuge_nascimento = "";
      this.conjuge_compoe_renda = 0;

      this.conjuge_renda_fixa = 0.0;
      this.conjuge_vinculo_empregaticio = null;
      this.conjuge_profissao = "";
      this.conjuge_profissao_empresa = "";
      this.conjuge_profissao_empresa_admissao = "";

      this.conjuge_renda_outras = 0.0;
      this.conjuge_renda_outras_origem = "";

    },

    // // // AÇÕES DO USUÁRIO // // //

    async validaCadastrado() {

      try {

        const response = await this.$api
          .get("/pessoas?fields=id&filters={%22imobiliaria%22:[%22" + this.imobiliaria_selecionada + "%22],%22str%22:[%22" + this.cpf.replace(/[^0-9]/g, "") + "%22]}");

        const dados = response.data;

        if (dados.success && dados.data.length > 0) { 
          this.loading = 1;
          this.carregarObjetoRota(dados.data[0].id);
        } else {

          if(!this.cpfInvalido) {

            this.loading++;

            const response = await this.$api
              .get("/pessoas/apicpfcnpj/" + this.cpf.replace(/[^0-9]/g, ""));

            const dados = response.data;

            if (dados.success) { 
              
              this.nome = dados.data.nome;
              this.nascimento = dados.data.nascimento;

            }

            this.loading--;

          }

        }

      } catch (error) {
        console.log("Falha ao verificar pessoa já cadastrada!");
        console.log(error);
      }

    },

    validaCEP() {

      if(this.residencia_cep == "" || this.cepPrevioValidacao == this.residencia_cep)
        return;

      const self = this;

      axios
        .get("https://brasilapi.com.br/api/cep/v1/" + this.residencia_cep)
        .then(function (response) {
          //Sucesso
          self.residencia_uf = response.data.state;
          self.residencia_cidade = response.data.city;
          self.residencia_bairro = response.data.neighborhood;
          self.residencia_logradouro = response.data.street;
        })
        .catch(function (error) {
          //Erro
          if (error.response.data.name == "CepPromiseError")
            self.cepInvalido = true;
        });
    },

    async validaCPF() {

      if(this.cpf == "" || this.cpfPrevioValidacao == this.cpf)
        return;

      this.cpfInvalido = false;
      if (this.$utils.isCPF(this.cpf)) {
        this.cpfInvalido = false;

        this.validaCadastrado();

      } else {
        this.cpfInvalido = true;
      }

    },

    async validaCPFConjuge() {

      if(this.conjuge_cpf == "" || this.cpfConjugePrevioValidacao == this.conjuge_cpf)
        return;

      this.cpfConjugeInvalido = false;
      if (this.$utils.isCPF(this.conjuge_cpf)) {
        this.cpfConjugeInvalido = false;
      } else {
        this.cpfConjugeInvalido = true;
      }

      if(!this.cpfConjugeInvalido) {

        try {

          this.loading++;

          const response = await this.$api
            .get("/pessoas/apicpfcnpj/" + this.conjuge_cpf.replace(/[^0-9]/g, ""));

          const dados = response.data;

          if (dados.success) { 
          
            this.conjuge_nome = dados.data.nome;
            this.conjuge_nascimento = dados.data.nascimento;

          }

          this.loading--;

        } catch (error) {
          this.loading--;
          console.log("Falha ao verificar o CPF do conjuge!");
          console.log(error);
        }

      }

    },

    adicionarTelefone() {
      if (this.telefone.length == 15 || this.telefone.length == 14) {
        this.telefoneInvalido = false;

        this.telefones.push({
          nome: this.telefone_nome,
          telefone: this.telefone.replace(/[^0-9]/g, ""),
          tipo: this.telefone.substr(5, 1) == "9" ? "Móvel" : "Fixo",
          e_whatsapp: (this.telefone_e_whatsapp)?1:0,
        });

      } else {
        this.telefoneInvalido = true;
      }
    },

    removerTelefone(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.telefones.splice(index, 1);
        },
      });
    },

    validaForm() {

      this.fieldsError = [];

      const campos = [
        "cpf","nome","nascimento","email","telefones",/*"residencia_cep",
        "residencia_logradouro","residencia_numero","residencia_bairro","residencia_cidade","residencia_uf",
        "residencia_situacao",*/ "estado_civil","possui_profissao"/*,"possui_outra_renda"*/
      ];

      if(this.possui_profissao) {
        campos.push("renda_fixa");
        campos.push("vinculo_empregaticio");
      }

      // const ve = this.vinculos_empregaticios.filter((obj) => obj.id == this.vinculo_empregaticio)[0];

      // if(typeof ve == "object" && ve != null) {
        
      //   if(ve.possui_empresa) {
      //     campos.push("profissao_empresa");
      //     campos.push("profissao_empresa_admissao");
      //     campos.push("profissao");
      //   }

      //   if(ve.possui_profissao) {
      //     campos.push("profissao");
      //   }

      // }

      // if(this.possui_outra_renda) {
      //   campos.push("renda_outras");
      //   campos.push("renda_outras_origem");
      // }

      // CONJUGE //

      if(this.possui_conjuge) {

        campos.push("conjuge_cpf");
        campos.push("conjuge_nome");
        campos.push("conjuge_nascimento");
        campos.push("conjuge_compoe_renda");

        if(this.conjuge_compoe_renda) {

          // campos.push("conjuge_possui_profissao");
          // campos.push("conjuge_possui_outra_renda");

          if(this.conjuge_possui_profissao) {
            campos.push("conjuge_renda_fixa");
            campos.push("conjuge_vinculo_empregaticio");
          }

          // const vec = this.vinculos_empregaticios.filter((obj) => obj.id == this.conjuge_vinculo_empregaticio)[0];

          // if(typeof vec == "object" && vec != null) {
            
          //   if(vec.possui_empresa) {
          //     campos.push("conjuge_profissao_empresa");
          //     campos.push("conjuge_profissao_empresa_admissao");
          //     campos.push("conjuge_profissao");
          //   }

          //   if(vec.possui_profissao) {
          //     campos.push("conjuge_profissao");
          //   }

          // }

          // if(this.conjuge_possui_outra_renda) {
          //   campos.push("conjuge_renda_outras");
          //   campos.push("conjuge_renda_outras_origem");
          // }

        }

      }

      for(const c of campos) {

        if(["possui_profissao",/*"possui_outra_renda",*/"conjuge_possui_profissao", 
        /*"conjuge_possui_outra_renda",*/ "conjuge_compoe_renda"].indexOf(c) > -1) {

          if(typeof this[c] != "number") {
            this.fieldsError.push(c);
          }

        } else {

          if(typeof this[c] == "undefined" || this[c] === null || this[c] === 0 || this[c] === "" || this[c] === "0") {
            this.fieldsError.push(c);
          } else if(Array.isArray(this[c]) && this[c].length == 0) {
            this.fieldsError.push(c);
          }

        }

      }

      if(this.fieldsError.length > 0) {

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Preencha corretamente todas as informações!",
          life: 5000,
        });

        return false;
      }

      return true;

    },

    criar_alterar() {

      if(this.tabAtiva == 0) {
        this.tabAtiva = 1;
        return;
      }

      if (this.telefones.length == 0 && this.telefone.length != 0) {
        this.adicionarTelefone();
      }

      if(!this.validaForm())
        return;

      const self = this;
      
      const criar = (
        (typeof this.$route.params.id != "string" ||
        this.$route.params.id == "") && this.pessoa == null
      );

      let dados = {
        "tipo": 'PF',
        "nome": this.nome,
        "cpfcnpj": this.cpf.replace(/[^0-9]/g, ""),
        "nascimento": this.$utils.formatDateToMysql(this.nascimento),
        // "logradouro": this.residencia_logradouro,
        // "numero": this.residencia_numero,
        // "complemento": this.residencia_complemento,
        // "bairro": this.residencia_bairro,
        // "cidade": this.residencia_cidade,
        // "uf": this.residencia_uf,
        // "cep": this.residencia_cep.replace(/[^0-9]/g, ""),
        "email": this.email,
        "estado_civil": this.estado_civil,
        // "empresa_cnpj": null,
        // "empresa_ramo_atividade": null,
        
        "renda_fixa": this.possui_profissao ? this.renda_fixa : 0.0,
        "vinculo_empregaticio": this.vinculo_empregaticio,

        "profissao": this.profissao,
        "profissao_empresa": this.profissao_empresa,
        "profissao_empresa_admissao": this.$utils.formatDateToMysql(this.profissao_empresa_admissao),

        "renda_outras": this.possui_outra_renda ? this.renda_outras : 0.0,
        "renda_outras_origem": this.renda_outras_origem,

        // "renda_lucro": null,
        "rg": this.rg,
        "rg_orgao": this.rg_orgao,
        "rg_expedicao": this.$utils.formatDateToMysql(this.rg_expedicao),
        // "cnae": null,
        // "residencia_anos": this.residencia_anos,
        // "residencia_situacao": this.residencia_situacao,
        "conjuge": {

          "cpfcnpj": this.conjuge_cpf.replace(/[^0-9]/g, ""),
          "nome": this.conjuge_nome,
          "rg": this.conjuge_rg,
          "rg_orgao": this.conjuge_rg_orgao,
          "rg_expedicao": this.$utils.formatDateToMysql(this.conjuge_rg_expedicao),
          "nascimento": this.$utils.formatDateToMysql(this.conjuge_nascimento),

          "vinculo_empregaticio": this.conjuge_vinculo_empregaticio,
          "renda_fixa": this.conjuge_possui_profissao ? this.conjuge_renda_fixa : 0.0,
          
          "profissao": this.conjuge_profissao,
          "profissao_empresa": this.conjuge_profissao_empresa,
          "profissao_empresa_admissao": this.$utils.formatDateToMysql(this.conjuge_profissao_empresa_admissao),

          "renda_outras": this.conjuge_possui_outra_renda ? this.conjuge_renda_outras : 0.0,
          "renda_outras_origem": this.conjuge_renda_outras_origem,

        },
        "contatos": this.telefones,
        // "emancipado": 0
      };

      if(typeof this.imobiliaria_selecionada == 'number') {
        dados['imobiliaria'] = this.imobiliaria_selecionada;
      }

      let pessoa_id;
      if (!criar) {
        dados["_method"] = "PUT";

        if(this.pessoa)
          pessoa_id = this.pessoa.id;
        else
          pessoa_id = this.$route.params.id;

      }

      this.$api
        .post(
          "/pessoas" + (!criar ? "/" + pessoa_id : ""),
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.$emit("save", response.data.data);

          if(criar) {
            self.$emit("created", response.data.data);
          } else {
            self.$emit("updated", response.data.data);
          }

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {
                "logradouro": "residencia_logradouro",
                "numero": "residencia_numero",
                "complemento": "residencia_complemento",
                "bairro": "residencia_bairro",
                "cidade": "residencia_cidade",
                "uf": "residencia_uf",
                "cep": "residencia_cep",                
              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

              self.tabAtiva = 0;

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    }

  },
};
</script>

<style scoped>
</style>